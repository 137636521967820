import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

import './styles/main.scss';

import { createPinia } from 'pinia'

const pinia = createPinia()
const app = createApp(App)

import {useAuthStore} from "@/stores/auth";

router.beforeEach(async(to, from) => {
    // instead of having to check every route record with
    // to.matched.some(record => record.meta.requiresAuth)
    const authStore = useAuthStore();
    await authStore.checkLogin();
    
    console.log('to', to, "requiresAuth", !!to.meta.requiresAuth);
    if (!!to.meta.requiresAuth && !authStore.isLoggedIn) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        return {
            path: '/login',
            // save the location we were at to come back later
            query: { redirect: to.fullPath },
        }
    }
})

app.use(pinia)
app.use(router)
app.mount('#app')

