import axios, {AxiosError, AxiosResponse} from "axios";
import router from '../router'

const axiosInstance = axios.create({
    withCredentials: true
});
// axiosInstance.interceptors.response.use((response: AxiosResponse<any, any>) => {
//     return response;
// }, async (error: AxiosError) => {
//     if(error.response && error.response.status === 401){
//         await router.push('/sign-in');
//     }
//     if(!error.response){
//         return Promise.reject("Unknown response");
//     }
//     return Promise.reject(error.response.data);
// })

export default axiosInstance;