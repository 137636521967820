<template>
  <section class="hero is-large">
    <div class="hero-body">
      <p class="title">
        404 - Page not found
      </p>
      <p class="subtitle">
        Sorry the page you were looking for doesn't exist
      </p>
      <router-link to="/" class="button">Home</router-link>
    </div>
  </section>
</template>

<script>
export default {
  name: "NotFound"
}
</script>

<style scoped>

</style>