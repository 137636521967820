export function getFirstDayOfWeek(d : Date) : Date {
    // 👇️ clone date object, so we don't mutate it
    const date = new Date(d);
    const day = date.getDay(); // 👉️ get day of week

    // 👇️ day of month - day of week (-6 if Sunday), otherwise +1
    const diff = date.getDate() - day + (day === 0 ? -6 : 1);

    return new Date(date.setDate(diff));
}

export function toShortTimeString(d : Date) : string {
    return d.toLocaleTimeString(undefined, {hour: 'numeric', minute: 'numeric', hour12: true});
}

export function getDayName(date: Date) {
    const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    return dayNames[date.getDay()];
}