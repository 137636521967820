import {defineStore} from 'pinia'
import axios from '../http'
import router from '../router';

interface ICheckAuthResponse {
    isAdmin: boolean;
    name: string;
    email : string;
    userId : string;
}

export const useAuthStore = defineStore('auth', {
    state: () => ({
        isLoggedIn: false,
        name: '',
        email : '',
        userId : '',
        isAdmin: false,
        isLoginChecked: false,
    }),
    actions: {

        async login(username: string, password: string): Promise<boolean> {
            try {
                const response = await axios.post<ICheckAuthResponse>('/api/auth/login', {username, password}).catch();
                if (response.status === 200) {
                    this.setLoggedIn(response.data);
                    if (router.currentRoute.value.query.redirect) {
                        await router.push(router.currentRoute.value.query.redirect as string);
                    } else {
                        await router.push('/');
                    }
                }
                return false;
            } catch (error) {
                this.isLoggedIn = false;
                return false;
            }
        },
        async logout(): Promise<void> {
            await axios.post('/api/auth/logout');
            this.setLoggedOut();
            await router.push('/login');
        },
        setLoggedIn(payload: ICheckAuthResponse) {
            this.isLoggedIn = true
            this.name = payload.name
            this.email = payload.email;
            this.isAdmin = payload.isAdmin
            this.userId = payload.userId;
        },
        setLoggedOut() {
            this.isLoggedIn = false
            this.name = ''
            this.email = '';
            this.userId = '';
            this.isAdmin = false
        },
        async checkLogin() {
            // do something to check if user is logged in
            // if logged in, set isLoggedIn to true
            // if not logged in, set isLoggedIn to false
            if (this.isLoginChecked) {
                return;
            }
            this.isLoginChecked = true;
            try {
                const response = await axios.post<ICheckAuthResponse>('/api/auth/checkAuth');
                if (response.status === 200) {
                    this.setLoggedIn(response.data)
                }
            } catch (error) {
                this.setLoggedOut();
            }
        },
        async requestPasswordReset(username: string): Promise<boolean> {
            try {
                const response = await axios.post('/api/auth/request-password-reset', {username});
                return response.status === 200;
            } catch (error) {
                return false;
            }
        },
        async resetPassword(token: string, password: string): Promise<boolean> {
            try {
                const response = await axios.post('/api/auth/reset-password', {token, password});
                return response.status === 200;
            } catch (error) {
                return false;
            }
        }
    }
        
    
})