import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import NotFound from '../views/NotFound.vue'
import PersonalTrainingView from '../views/PersonalTrainingView.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: {requiresAuth: true},
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView,
        meta: {requiresAuth: false}
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import(/* webpackChunkName: "forgot-password" */ '../views/ForgotPasswordView.vue'),
        meta: {requiresAuth: false}
    },
    {
        path: '/reset-password/:token',
        name: 'reset-password',
        component: () => import(/* webpackChunkName: "reset-password" */ '../views/ResetPasswordView.vue'),
        meta: {requiresAuth: false}
    },
    {
        path: '/personal-training',
        name: 'personal-training',
        component: PersonalTrainingView,
        meta: {requiresAuth: true}
    },
    {
        path: '/auto-forfeit',
        name: 'auto-forfeit',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AutoForfeitView.vue'),
        meta: {requiresAuth: true}
    },
    {
        path: '/booking/:id/cancel',
        name: 'personal-training-cancel',
        component: () => import(/* webpackChunkName: "cancel" */ '../views/PersonalTrainingCancelView.vue'),
        meta: {requiresAuth: false}
    },
    {
        path: '/profile',
        name: 'user-profile',
        component: () => import(/* webpackChunkName: "cancel" */ '../views/UserProfileView.vue'),
        meta: {requiresAuth: true}
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        component: NotFound,
        meta: {requiresAuth: false}
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
